<template>
  <div class="h-100">
    <layout ref="layout">
      <template v-slot:body>
        <div class="mb-5">
          <div class="row">
            <div class="col-12 order-3 order-md-2">
              <div class="card">
                <h5 class="card-header">
                  Webhooks de tokens deixa vazio desativa
                </h5>
                <div class="card-body">
                  <div>
                    <label class="form-label">Validar token</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.token.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.token.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Esse rota deve retornar o status 200 ou 204 para validar o
                      token se é valido ou não.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 order-3 order-md-2 mt-3">
              <div class="card">
                <h5 class="card-header">Webhooks do whatsapp</h5>
                <div class="card-body">
                  <div>
                    <label class="form-label">Gerar QR Code</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.qr.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.qr.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Envia junto o qrcode em base64 gerado
                    </div>
                  </div>
                  <hr />
                  <div>
                    <label class="form-label">Parar o qr code</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.qr_fail.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.qr_fail.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Envia quando para de gerar o qrcode
                    </div>
                  </div>
                  <hr />
                  <div class="mt-2">
                    <label class="form-label">Autenticado</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.auth.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.auth.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando o cliente é autenticado a primeira vez pelo
                      sistema, ocorre novamente quando a conexão for fechada
                      manualmente ou falha na autenticação
                    </div>
                  </div>
                  <hr />
                  <div class="mt-2">
                    <label class="form-label">Reboot</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.reboot.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.reboot.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando o cliente é reiniciado e já estava
                      autenticado uma vez
                    </div>
                  </div>
                  <hr />
                  <div class="mt-2">
                    <label class="form-label">Mensagem</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.mensagem.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.mensagem.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando é recebido uma mensagem pelo cliente,
                      reponder esse metodo com status 200 e um json {"value":
                      "mensagem"} ira responder a mensagem automaticamente
                    </div>
                  </div>
                  <hr />
                  <div class="mt-2">
                    <label class="form-label">Logout</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.logout.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.logout.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando é parado pelo cliente no celular ou pela
                      roda da api de logout
                    </div>
                  </div>
                  <hr />
                  <div class="mt-2">
                    <label class="form-label">Disconect</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.disconect.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.disconect.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando o cliente cai por algum motivo, ou nao
                      consegue autenticar apos reboot
                    </div>
                  </div>
                  <hr />
                  <div class="mt-2">
                    <label class="form-label">Online</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.online.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.online.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando o cliente fica online por qualquer motivo
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label">Offline</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.offline.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.offline.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando o cliente fica offline por qualquer motivo
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label">Mensagem enviada</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.mensagem_enviada.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.mensagem_enviada.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando a mensagem chega até o servidor do whatsapp
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label">Mensagem recebida</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.received.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.received.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando o cliente recebe a mensagem
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label">Mensagem lida</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <select
                          class="form-select bg-primary text-white"
                          v-model="data.read.method"
                        >
                          <option
                            v-for="(o, i) in methods"
                            class="text-white"
                            :value="o"
                            :key="i"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.read.url"
                        placeholder="http://teste.com"
                      />
                    </div>

                    <div class="form-text">
                      Evento quando o cliente le a mensagem
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buy-now">
            <button class="btn btn-primary btn-buy-now" @click="salvar">
              Salvar
            </button>
          </div>
        </div>
      </template>
    </layout>
    <alert ref="alert"></alert>
  </div>
</template>

<script>
import layout from "../components/layout/Tema.vue";
import stick from "../components/Stick.vue";
import alert from "../components/Alert.vue";
export default {
  components: {
    layout,
    stick,
    alert,
  },
  data() {
    return {
      methods: ["GET", "POST", "PUT", "DELETE"],
      data: {
        token: {
          url: null,
          method: "POST",
        },
        qr: {
          url: null,
          method: "POST",
        },
        auth: {
          url: null,
          method: "POST",
        },
        reboot: {
          url: null,
          method: "POST",
        },
        online: {
          url: null,
          method: "POST",
        },
        offline: {
          url: null,
          method: "POST",
        },
        mensagem: {
          url: null,
          method: "POST",
        },
        logout: {
          url: null,
          method: "POST",
        },
        disconect: {
          url: null,
          method: "POST",
        },
        received: {
          url: null,
          method: "POST",
        },
        mensagem_enviada: {
          url: null,
          method: "POST",
        },
        read: {
          url: null,
          method: "POST",
        },
        qr_fail: {
          url: null,
          method: "POST",
        },
      },
    };
  },
  methods: {
    salvar() {
      this.urlPost("/system/webhook", this.data)
        .then((res) => {
          this.$refs.alert.show(
            "Salvo",
            "O registro salvo com sucesso!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch(() => {
          this.$refs.alert.show(
            "Falha",
            "Não foi possível salvar!",
            null,
            5000,
            "bg-danger"
          );
        });
    },
  },
  mounted() {
    this.urlGet("/system/webhook").then((res) => {
      this.data = res.data;
    });
  },
};
</script>
<style>
</style>