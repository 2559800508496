<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="true"
          @delete="$refs.datatable.update()"
          @create="$refs.datatable.update()"
          name="Token"
          names="Tokens"
          @oncreate="onform"
          @onupdate="onform"
          @filter="$refs.datatable.applyFilters($event)"
          url="/system/api-token"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <Datatable
              class="text-nowrap"
              ref="datatable"
              :fields="fields"
              :url="'/system/api-token/query'"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:qr="data">
                <div class="text-center">
                  <button
                    class="btn btn-white p-0"
                    @click="showQR = data.row.token"
                    tabindex="_blank"
                  >
                    <i class="bx bx-qr text-primary"></i>
                  </button>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div>
              <div class="mb-3">
                <label class="form-label">Token *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.token"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.token"
                ></Error>
              </div>
              <div class="mb-3">
                <label class="form-label">Data</label>
                <textarea
                  class="form-control"
                  :value="
                    form.crud.form.data
                      ? JSON.stringify(form.crud.form.data)
                      : ''
                  "
                  @change="
                    form.crud.form.data = JSON.parse($event.target.value)
                  "
                ></textarea>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.data"
                ></Error>
              </div>
              <div class="mb-3" v-if="form.crud.form.numeros">
                <label class="form-label">Números</label>

                <div class="table-responsive text-nowrap datatable-table">
                  <table
                    class="table table-striped table-bordered"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th style="width: 70px">
                          <div class="orderby-header">
                            <span>ID</span>
                          </div>
                        </th>
                        <th>
                          <div class="orderby-header">
                            <span>Número</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(n, i) in form.crud.form.numeros" :key="i">
                        <td>
                          {{ n._serialized }}
                        </td>
                        <td>
                          {{ n.user }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="mb-3" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  :value="new Date(form.crud.form.created).toLocaleString()"
                />
              </div>
              <div class="mb-3" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  :value="new Date(form.crud.form.updated).toLocaleString()"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Token</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.token"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Email</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.data"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Números</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.count"
                />
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
    <div class="modal fade" tabindex="-1" ref="modal_qr">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">
              QR Code para {{ showQR }}
            </h5>
            <button type="button" class="btn-close" @click="closeQr"></button>
          </div>
          <div class="modal-body text-center" v-if="showQR">
            <img
              :src="apiUrl + '/auth/qr-imagem?token=' + showQR"
              frameborder="0"
              style="width: 276px"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="closeQr">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
  },
  data() {
    return {
      showQR: null,
      where: {
        token: {
          operator: "=",
        },
        count: {
          operator: "=",
        },
        data: {
          operator: "LIKE",
          replace: "%{value}%",
        },
      },
      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Token", value: "token" },
        {
          name: "Email",
          value: "data",
          format: (v, d) => {
            if (v != null && v.length > 0) {
              return v[0].email;
            } else {
              return "-";
            }
          },
        },
        {
          name: "Números",
          value: "count",
          format: (v, d) => {
            return v;
          },
        },
        {
          name: "Criado",
          value: "created",
          style: { width: "173px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "QR",
          type: "slot",
          value: "qr",
          orderby: false,
          style: { minWidth: "100px", width: "100px", textAlign: "center" },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          style: { minWidth: "100px", width: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    onform(form) {
      if (form.password == "") {
        delete form.password;
      }
      if ((form.email == "" || form.email == null) && form.login != null) {
        form.email = form.login + "@" + this.appName.toLowerCase() + ".com";
      }
    },
    closeQr() {
      $(this.$refs.modal_qr).modal("hide");
      setTimeout(() => {
        this.showQR = null;
      }, 300);
    },
  },
  watch: {
    showQR(v) {
      if (v != null) {
        $(this.$refs.modal_qr).modal({
          backdrop: "static",
          keyboard: false,
        });
      }
    },
  },
};
</script>