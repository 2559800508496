export default {
    data() {
        return {

        }
    },
    methods: {
        toGb(d) {
            if (d == null) {
                return '';
            }
            if (d < 1024 * 1024 * 1024) {
                return (d / (1024 * 1024)).toFixed(2) + ' MB';
            }
            return (d / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        },
    }
}