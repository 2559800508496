import axios from 'axios'
const DEV = false;
var SESSION = localStorage.getItem('session');
const API_URL = DEV ? 'http://192.168.1.105:8000/api' : '/api';
var HEADERS = {

};
if (SESSION != null) {
    SESSION = JSON.parse(SESSION);
    HEADERS['Authorization'] = 'Bearer ' + SESSION.token;
}

function parseUrl(url) {
    return API_URL + url;
}
export default {
    data() {
        return {
            version: '3.3',
            appName: 'Whatsapp',
            appFullName: 'Whatsapp Fidelidade',
            apiUrl: API_URL
        }
    },
    methods: {
        isMobile() {
            var isMobile = {
                Android: function() {
                    return navigator.userAgent.match(/Android/i);
                },
                BlackBerry: function() {
                    return navigator.userAgent.match(/BlackBerry/i);
                },
                iOS: function() {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
                },
                Opera: function() {
                    return navigator.userAgent.match(/Opera Mini/i);
                },
                Windows: function() {
                    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
                },
                any: function() {
                    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
                }
            }
            return isMobile.any() != null;
        },
        crudCreate(type, data) {
            return this.urlPost(type, data);
        },
        crudUpdate(type, data) {
            return this.urlPut(type, data);
        },
        crudDelete(type, id) {
            return this.urlDelete(type + '/' + id);
        },
        crudList(type, filter) {
            return this.urlPost(type, filter);
        },
        urlGet(url) {
            return axios.get(parseUrl(url), { headers: HEADERS });
        },
        urlPost(url, data) {
            return axios.post(parseUrl(url), data, { headers: HEADERS });
        },
        urlDelete(url) {
            return axios.delete(parseUrl(url), { headers: HEADERS });
        },
        urlPut(url, data) {
            return axios.put(parseUrl(url), data, { headers: HEADERS });
        },
        login(data, success, error) {
            this.urlPost('/system/login', data).then((res) => {
                SESSION = res.data;
                HEADERS['Authorization'] = 'Bearer ' + SESSION.token;
                localStorage.setItem('session', JSON.stringify(SESSION));
                success(res.data);

            }).catch((e) => {
                error(e.response)
            });
        },
        logout() {
            delete HEADERS['Authorization'];
            SESSION = null;
            localStorage.removeItem('session');
        },
        checkSession() {
            return SESSION != null;
        },
        getRun(url, action, time) {
            var run = true;
            this.urlGet(url).then((data) => {
                action(data);
            }).catch(() => {

            }).finally(() => {
                run = false;
            })
            return setInterval(() => {
                if (!run) {
                    run = true;
                    this.urlGet(url).then((data) => {
                        action(data);
                    }).catch(() => {

                    }).finally(() => {
                        run = false;
                    })
                }
            }, time)
        }
    }
}