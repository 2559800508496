<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :cancreate="false"
          :modal="true"
          @delete="$refs.datatable.update()"
          @create="$refs.datatable.update()"
          name="Cliente"
          names="Clientes"
          @oncreate="onform"
          @onupdate="onform"
          @filter="$refs.datatable.applyFilters($event)"
          @stopall="pararTodos"
          @playall="iniciarTodos"
          url="/system/client"
          :btns="[
            { icon: 'play', title: 'Iniciar todos', action: 'playall' },
            { icon: 'stop-circle', title: 'Parar todos', action: 'stopall' },
          ]"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :autoreload="5000"
              :fields="fields"
              :url="'/system/client/query'"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
              :height="72"
            >
              <template v-slot:img="data">
                <div class="text-center">
                  <img
                    :src="
                      apiUrl +
                      '/wpp/picture/' +
                      data.row.info.data.me.user +
                      '?token=' +
                      data.row.token
                    "
                    height="30"
                    alt="img"
                    v-if="
                      data.row.online &&
                      data.row.info &&
                      data.row.info.data &&
                      data.row.info.data.me
                    "
                  />
                  <img
                    v-else-if="data.row.qr"
                    height="30"
                    :src="'data:image/png;base64,' + data.row.qr"
                    alt=""
                  />
                </div>
              </template>
              <template v-slot:status="data">
                <div class="text-center">
                  <button
                    class="btn btn-white p-0 mx-1"
                    @click="
                      showToken = data.row.token;
                      showModal('back');
                    "
                    title="Hitórico"
                  >
                    <i class="bx bx-time text-primary"></i>
                  </button>
                  <button
                    class="btn btn-white p-0 mx-1"
                    title="Números"
                    @click="
                      showToken = data.row.token;
                      numeros = data.row.numeros;
                      showModal('number');
                    "
                  >
                    <i class="bx bx-phone-call text-info"></i>
                  </button>
                  <button
                    v-if="data.row.status != 'PARADO'"
                    class="btn btn-white p-0 mx-1"
                    title="Parar"
                    @click="
                      showToken = data.row.token;
                      showModal('stop');
                    "
                  >
                    <i class="bx bx-stop-circle text-danger"></i>
                  </button>
                  <button
                    v-else
                    class="btn btn-white p-0 mx-1"
                    title="Iniciar"
                    @click="startClient(data.row.token)"
                  >
                    <i class="bx bx-play text-success"></i>
                  </button>
                  <button
                    v-if="data.row.status == 'PARADO'"
                    class="btn btn-white p-0 mx-1"
                    title="Apagar"
                    @click="
                      showToken = data.row.token;
                      logoutClient();
                      showToken = null;
                    "
                  >
                    <i class="bx bx-trash text-danger"></i>
                  </button>
                </div> </template
            ></Datatable>
          </template>
          <template v-slot:form="form">
            <div>
              <div class="mb-3">
                <label class="form-label">Token *</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  v-model="form.crud.form.token"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.token"
                ></Error>
              </div>
              <div class="mb-3">
                <label class="form-label">Status *</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  v-model="form.crud.form.status"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.status"
                ></Error>
              </div>
              <div class="mb-3" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  :value="new Date(form.crud.form.created).toLocaleString()"
                />
              </div>
              <div class="mb-3" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  :value="new Date(form.crud.form.updated).toLocaleString()"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Token</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.token"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Status</label>
                <select class="form-select" v-model="data.filters.status">
                  <option :value="undefined">Todos</option>
                  <option value="PARADO">PARADO</option>
                  <option value="ONLINE">ONLINE</option>
                  <option value="GERANDO_QR">GERANDO_QR</option>
                  <option value="FINALIZANDO">FINALIZANDO</option>
                  <option value="FILA">FILA</option>
                  <option value="REBOOT">REBOOT</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
    <div class="modal fade" tabindex="-1" ref="modal_back">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">
              Histórico de {{ showToken }}
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="showModal('back', false)"
            ></button>
          </div>
          <div
            class="modal-body text-nowrap"
            style="min-height: calc(100vh - 200px); height: calc(100vh - 200px)"
          >
            <Datatable
              style="
                min-height: calc(100vh - 240px);
                height: calc(100vh - 240px);
              "
              v-if="showToken"
              ref="datatable"
              :fields="logFields"
              :url="'/system/log-token/query'"
              :where="{ token: { operator: '=', value: showToken } }"
              :wait="500"
              :iniorderby="{ column: 'created', type: 'DESC' }"
            ></Datatable>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="showModal('back', false)"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_number">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">
              Números de {{ showToken }}
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="showModal('number', false)"
            ></button>
          </div>
          <div class="modal-body">
            <div
              class="table-responsive text-nowrap datatable-table"
              v-if="numeros"
            >
              <table
                class="table table-striped table-bordered"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th style="width: 70px">
                      <div class="orderby-header">
                        <span>ID</span>
                      </div>
                    </th>
                    <th>
                      <div class="orderby-header">
                        <span>Número</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(n, i) in numeros" :key="i">
                    <td>
                      {{ n._serialized }}
                    </td>
                    <td>
                      {{ n.user }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-else>
              <span>Nenhum número</span>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="showModal('number', false)"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_stop">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Ação de {{ showToken }}</h5>
            <button
              type="button"
              class="btn-close"
              @click="showModal('stop', false)"
            ></button>
          </div>
          <div class="modal-body">
            <h5>Oque deseja fazer com o cliente {{ showToken }}?</h5>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="
                pararClient();
                showModal('stop', false);
              "
            >
              Parar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="
                logoutClient();

                showModal('stop', false);
              "
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    Alert,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        token: {
          operator: "=",
        },
        status: {
          operator: "=",
        },
      },
      logFields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Status", value: "status" },
        {
          name: "Criado",
          value: "created",
          style: { width: "173px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
      ],
      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        {
          name: "Foto",
          type: "slot",
          value: "img",
          orderby: false,
          style: { minWidth: "50px", width: "50px", textAlign: "center" },
        },
        {
          name: "Nome",
          value: "token",
          style: { maxWidth: "50vw" },
          format: (v, d) => {
            if (d.info && d.info.data) {
              return (
                v +
                " - " +
                d.info.data.pushname +
                " - " +
                d.info.data.me.user +
                (d.data && d.data.length > 0 ? " - " + d.data[0].email : "")
              );
            }
            return v;
          },
        },
        {
          name: "Status",
          value: "status",
          style: { minWidth: "100px", width: "100px", textAlign: "center" },
        },
        {
          name: "Auth",
          value: "auth",
          style: {
            minWidth: "100px",
            width: "100px",
            textAlign: "center",
          },
          format: (v, d) => {
            if (v != null && v) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "slot",
          orderby: false,
          value: "status",
          style: { minWidth: "150px", width: "150px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    pararTodos() {
      this.urlPost("/system/parar-todos")
        .then(() => {
          this.$refs.alert.show(
            "Parado",
            "Todos os clientes foram parados!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch(() => {
          this.$refs.alert.show(
            "Erro",
            "Falha ao tentar parar os clientes!",
            null,
            5000,
            "bg-danger"
          );
        });
    },
    iniciarTodos() {
      this.urlPost("/system/iniciar-todos")
        .then(() => {
          this.$refs.alert.show(
            "Iniciado",
            "Todos os clientes foram iniciados!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch(() => {
          this.$refs.alert.show(
            "Erro",
            "Falha ao tentar iniciar os clientes!",
            null,
            5000,
            "bg-danger"
          );
        });
    },
    startClient(token) {
      this.urlPost("/system/iniciar/" + token)
        .then(() => {
          this.$refs.alert.show(
            "Iniciado",
            "O cliente foi iniciado!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch(() => {
          this.$refs.alert.show(
            "Erro",
            "Falha ao tentar iniciar o cliente!",
            null,
            5000,
            "bg-danger"
          );
        });
    },
    pararClient() {
      this.urlDelete("/system/parar/" + this.showToken)
        .then(() => {
          this.$refs.alert.show(
            "Parado",
            "O cliente está parando!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch(() => {
          this.$refs.alert.show(
            "Erro",
            "Falha ao tentar parar o cliente!",
            null,
            5000,
            "bg-danger"
          );
        });
    },
    logoutClient() {
      this.urlDelete("/system/logout/" + this.showToken)
        .then(() => {
          this.$refs.alert.show(
            "Logout",
            "Fazendo logout no cliente!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch(() => {
          this.$refs.alert.show(
            "Erro",
            "Falha ao tentar dar logout no cliente!",
            null,
            5000,
            "bg-danger"
          );
        });
    },
    showModal(type, show = true) {
      switch (type) {
        case "back": {
          if (show) {
            $(this.$refs.modal_back).modal({
              backdrop: "static",
              keyboard: false,
            });
          } else {
            $(this.$refs.modal_back).modal("hide");
          }

          break;
        }
        case "number": {
          if (show) {
            $(this.$refs.modal_number).modal({
              backdrop: "static",
              keyboard: false,
            });
          } else {
            $(this.$refs.modal_number).modal("hide");
          }

          break;
        }
        case "stop": {
          if (show) {
            $(this.$refs.modal_stop).modal({
              backdrop: "static",
              keyboard: false,
            });
          } else {
            $(this.$refs.modal_stop).modal("hide");
          }

          break;
        }
      }
      if (!show) {
        setTimeout(() => {
          this.showToken = null;
        }, 300);
      }
    },
    onform(form) {
      if (form.password == "") {
        delete form.password;
      }
      if ((form.email == "" || form.email == null) && form.login != null) {
        form.email = form.login + "@" + this.appName.toLowerCase() + ".com";
      }
    },
  },
};
</script>