<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :cancreate="false"
          :modal="true"
          @delete="$refs.datatable.update()"
          @create="$refs.datatable.update()"
          @deleteall="deleteall"
          name="Log"
          names="Logs"
          @oncreate="onform"
          @onupdate="onform"
          @filter="$refs.datatable.applyFilters($event)"
          url="/system/log-token"
          :btns="[
            { icon: 'trash', title: 'Apagar todos', action: 'deleteall' },
          ]"
        >
          <template v-slot:list="form">
            <Datatable
              class="text-nowrap"
              ref="datatable"
              :fields="fields"
              :url="'/system/log-token/query'"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
              :iniorderby="{ column: 'created', type: 'DESC' }"
            ></Datatable>
          </template>
          <template v-slot:form="form">
            <div>
              <div class="mb-3">
                <label class="form-label">Token *</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  v-model="form.crud.form.token"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.token"
                ></Error>
              </div>
              <div class="mb-3">
                <label class="form-label">Status *</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  v-model="form.crud.form.status"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.status"
                ></Error>
              </div>
              <div class="mb-3" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  :value="new Date(form.crud.form.created).toLocaleString()"
                />
              </div>
              <div class="mb-3" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  :value="new Date(form.crud.form.updated).toLocaleString()"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Token</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.token"
                />
              </div>
              <select class="form-select" v-model="data.filters.status">
                <option :value="undefined">Todos</option>
                <option value="GERANDO_QR">GERANDO_QR</option>
                <option value="AUTH">AUTH</option>
                <option value="STOP">STOP</option>
                <option value="PARADO">PARADO</option>
                <option value="OVERFLOW">OVERFLOW</option>
                <option value="LOGOUT">LOGOUT</option>
                <option value="MAX_TRY">MAX_TRY</option>
              </select>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
  },
  data() {
    return {
      where: {
        token: {
          operator: "=",
        },
        status: {
          operator: "=",
        },
      },
      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Token", value: "token" },
        { name: "Status", value: "status" },
        {
          name: "Criado",
          value: "created",
          style: { width: "173px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          style: { minWidth: "100px", width: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    deleteall() {
      this.urlDelete("/system/clear-log/all");
      this.$refs.datatable.update();
    },
    onform(form) {
      if (form.password == "") {
        delete form.password;
      }
      if ((form.email == "" || form.email == null) && form.login != null) {
        form.email = form.login + "@" + this.appName.toLowerCase() + ".com";
      }
    },
  },
};
</script>