<template>
  <div class="h-100">
    <layout ref="layout">
      <template v-slot:body>
        <div>
          <div class="row">
            <div class="col-12">
              <div class="card h-100" style="overflow-x: auto">
                <div
                  class="
                    card-header
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <h4 class="card-title m-0 me-2">Terminal</h4>
                </div>
                <div class="card-body" v-if="info">
                  <div v-for="(log, i) in info" :key="i">
                    <div class="row" v-if="log.data.trim() != ''">
                      <div class="col-4 col-md-3">
                        {{ new Date(log.created).toLocaleString() }}
                      </div>
                      <div class="col-8 col-md-9">
                        <p class="m-0">
                          {{ log.data }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <alert ref="alert"></alert>
  </div>
</template>

<script>
import layout from "../components/layout/Tema.vue";
import stick from "../components/Stick.vue";
import alert from "../components/Alert.vue";
export default {
  components: {
    layout,
    stick,
    alert,
  },
  data() {
    return {
      info: [],
      id: 0,
      run: null,
    };
  },
  methods: {
    salvar() {
      this.urlPost("/system/monitorar", this.monitor)
        .then((res) => {
          this.$refs.alert.show(
            "Salvo",
            "O registro salvo com sucesso!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch(() => {
          this.$refs.alert.show(
            "Falha",
            "Não foi possível salvar!",
            null,
            5000,
            "bg-danger"
          );
        });
    },
  },
  mounted() {
    this.run = this.getRun(
      "/system/terminal",
      (res) => {
        for (const log of res.data.logs) {
          if (log.id > this.id) {
            this.info.unshift(log);
          }
        }
        this.id = res.data.max;
      },
      5000
    );
  },
  beforeUnmount() {
    clearInterval(this.run);
  },
};
</script>
<style>
</style>