<template>
  <div class="h-100">
    <layout ref="layout">
      <template v-slot:body>
        <div>
          <div class="row">
            <div class="col-12">
              <div class="card h-100" style="overflow-x: auto">
                <div
                  class="
                    card-header
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <h5 class="card-title m-0 me-2">Processos</h5>
                </div>
                <div class="card-body" v-if="info">
                  <div class="row" v-if="monitor">
                    <div class="col-4">
                      <label class="form-label"
                        >Tempo de monitoramento (milissegundos)</label
                      >
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          v-model="monitor.loop"
                        />
                      </div>
                      <div class="form-text">
                        Intervalo de tempo para ser monitorado
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="form-label"
                        >Tempo de uso de CPU (milissegundos)</label
                      >
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          v-model="monitor.max_cpu"
                        />
                      </div>
                      <div class="form-text">Tempo máximo</div>
                    </div>
                    <div class="col-2">
                      <div class="d-flex h-100">
                        <div class="form-check form-switch m-auto">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="logout"
                            v-model="monitor.logout"
                          />
                          <label class="form-check-label" for="logout"
                            >{{ monitor.logout ? "Logout" : "Parar" }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="d-flex h-100">
                        <button class="btn btn-primary m-auto" @click="salvar">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <stick :info="[info]" :m="1"></stick>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <alert ref="alert"></alert>
  </div>
</template>

<script>
import layout from "../components/layout/Tema.vue";
import stick from "../components/Stick.vue";
import alert from "../components/Alert.vue";
export default {
  components: {
    layout,
    stick,
    alert,
  },
  data() {
    return {
      info: null,
      monitor: null,
      run: null,
    };
  },
  methods: {
    salvar() {
      this.urlPost("/system/monitorar", this.monitor)
        .then((res) => {
          this.$refs.alert.show(
            "Salvo",
            "O registro salvo com sucesso!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch(() => {
          this.$refs.alert.show(
            "Falha",
            "Não foi possível salvar!",
            null,
            5000,
            "bg-danger"
          );
        });
    },
  },
  mounted() {
    this.run = this.getRun(
      "/system/tree",
      (res) => {
        this.info = res.data;
      },
      5000
    );
    this.urlGet("/system/monitorar")
      .then((e) => {
        this.monitor = e.data;
      })
      .catch(() => {});
  },
  beforeUnmount() {
    clearInterval(this.run);
  },
};
</script>
<style>
</style>