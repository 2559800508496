<template>
  <router-view />
</template>

<style>
</style>

<script>
export default {
  mounted() {
    setTimeout(() => {
      if (!this.$route.meta.public && !this.checkSession()) {
        this.$router.push("/login");
      }
    }, 100);
  },
};
</script>
