<template>
  <div class="container-xxl container-p-y">
    <div class="misc-wrapper">
      <h2 class="mb-2 mx-2">Página não encontrada :(</h2>
      <p class="mb-4 mx-2">Oops! 😖 A URL requisitada não foi encontrada.</p>
      <button class="btn btn-primary" @click="$router.push('/')">
        Ir para o início
      </button>
      <div class="mt-3">
        <img
          src="/img/page-misc-error-light.png"
          alt="page-misc-error-light"
          width="500"
          class="img-fluid"
          data-app-dark-img="illustrations/page-misc-error-dark.png"
          data-app-light-img="illustrations/page-misc-error-light.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>