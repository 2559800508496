<template>
  <div class="layout-wrapper layout-content-navbar">
    <div :class="'layout-container' + (colapse ? ' layout-menu-fixed' : '')">
      <aside class="layout-menu menu-vertical menu bg-menu-theme">
        <div class="app-brand demo">
          <a href="/" class="app-brand-link">
            <span class="app-brand-logo demo">
              <img src="/img/logo.png" height="30" alt="" />
            </span>
            <span class="app-brand-text demo menu-text fw-bolder ms-2">{{
              appName
            }}</span>
          </a>

          <a
            href="javascript:void(0);"
            @click="colapse = false"
            v-if="colapse"
            style="display: block !important"
            class="layout-menu-toggle menu-link text-large ms-auto d-block"
          >
            <i class="bx bx-chevron-left bx-sm align-middle"></i>
          </a>
        </div>
        <div
          class="menu-inner-shadow"
          style="display: block"
          v-if="colapse"
        ></div>
        <div class="menu-inner-shadow" v-else></div>

        <ul class="menu-inner py-1">
          <!-- Dashboard -->
          <li
            :class="
              (m.path ? 'menu-item' : 'menu-header') +
              (menuItem == m.path ? ' active' : '')
            "
            v-for="(m, i) in menu"
            :key="i"
          >
            <a
              v-if="m.path"
              @click="
                $router.push(m.path);
                colapse = false;
              "
              class="menu-link"
              style="cursor: pointer"
            >
              <i :class="'menu-icon tf-icons bx bx-' + m.icon"></i>
              <div data-i18n="Analytics">{{ m.name }}</div>
            </a>
            <span class="menu-header-text" v-else>{{ m.name }}</span>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              style="cursor: pointer"
              @click="
                logout();
                $router.push('/login');
              "
              ><i class="menu-icon tf-icons bx bx-power-off"></i>
              <div data-i18n="Analytics">Logout</div></a
            >
          </li>
        </ul>
      </aside>
      <div
        class="layout-overlay layout-menu-toggle d-block"
        v-if="colapse"
      ></div>
      <div class="layout-page app-scroll">
        <!-- Navbar -->

        <nav
          class="
            d-flex d-md-none
            layout-navbar
            container-xxl
            navbar navbar-expand-xl navbar-detached
            align-items-center
            bg-navbar-theme
          "
        >
          <div
            class="
              layout-menu-toggle
              navbar-nav
              align-items-xl-center
              me-3 me-xl-0
              d-xl-none
            "
          >
            <a
              class="nav-item nav-link px-0 me-xl-4"
              href="javascript:void(0)"
              @click="colapse = true"
            >
              <i class="bx bx-menu bx-sm"></i>
            </a>
          </div>
        </nav>
        <div class="content-wrapper">
          <!-- Content -->

          <div class="container-xxl flex-grow-1 container-p-y">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItem: window.location.pathname,
      menu: [
        { name: "Home", path: "/", icon: "chip" },
        { name: "Painel" },
        { name: "Clientes", path: "/clientes", icon: "user" },
        { name: "Processos", path: "/processos", icon: "sitemap" },
        { name: "Threads", path: "/threads", icon: "pulse" },
        { name: "Terminal", path: "/terminal", icon: "terminal" },
        { name: "Cadastro" },
        { name: "Tokens", path: "/tokens", icon: "key" },
        { name: "Logs", path: "/logs", icon: "hdd" },
        { name: "Webhooks", path: "/webhooks", icon: "link" },
        { name: "Api" },
        { name: "Documentação", path: "/api", icon: "file" },
        { name: "Sistema" },
      ],
      colapse: false,
    };
  },
};
</script>

<style>
.app-scroll {
  max-height: 100vh;
  overflow: auto;
}
.layout-menu-fixed .menu-vertical {
  animation: menu-expand ease-in-out 0.1s !important;
  transform: translate3d(0, 0, 0);
}
@keyframes menu-expand {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0%, 0%);
  }
}
</style>