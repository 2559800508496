import { createRouter, createWebHistory } from 'vue-router'
import Dashborad from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Token from '../views/crud/Token.vue'
import Log from '../views/crud/Log.vue'
import Thread from '../views/Thread.vue'
import Webhook from '../views/Webhook.vue'
import Process from '../views/Process.vue'
import Api from '../views/Api.vue'
import Client from '../views/crud/Client.vue'
import Page404 from '../views/Page404.vue'
import Terminal from '../views/Terminal.vue'


const routes = [{
        path: '/',
        name: 'Dashborad',
        component: Dashborad,
    },
    {
        path: '/clientes',
        name: 'Client',
        component: Client,
    },
    {
        path: '/tokens',
        name: 'Token',
        component: Token,
    },
    {
        path: '/processos',
        name: 'Process',
        component: Process,
    },
    {
        path: '/threads',
        name: 'Thread',
        component: Thread,
    }, {
        path: '/logs',
        name: 'Log',
        component: Log,
    }, {
        path: '/api',
        name: 'Api',
        component: Api,
    }, {
        path: '/webhooks',
        name: 'Webhook',
        component: Webhook,
    },
    {
        path: '/terminal',
        name: 'Terminal',
        component: Terminal,
    }, {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { public: true }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Page404',
        component: Page404,
        meta: { public: true }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router