<template>
  <div class="h-100">
    <div class="theme-loader">
      <div class="loader-track">
        <div class="preloader-wrapper">
          <div class="spinner-layer spinner-blue">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
          <div class="spinner-layer spinner-red">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>

          <div class="spinner-layer spinner-yellow">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>

          <div class="spinner-layer spinner-green">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pre-loader end -->

    <div class="row m-0 h-100">
      <div class="col-12 col-md-3 login-card">
        <div>
          <div class="px-3 mt-5 w-100 md-float-material form-material">
            <div class="card-block">
              <div class="mb-3">
                <div class="text-center">
                  <img src="/img/logo.png" alt="" height="200" />
                </div>
              </div>
              <h3 class="text-center my-5">Whatsapp Fidelidade</h3>
              <div class="mb-3">
                <div class="mb-5 form-password-toggle">
                  <div class="input-group input-group-merge">
                    <input
                      :type="inpuType"
                      class="form-control"
                      placeholder="···"
                      v-model="senha"
                      @keyup.enter="logar"
                    />
                    <span
                      class="input-group-text cursor-pointer"
                      @click="
                        inpuType == 'password'
                          ? (inpuType = 'text')
                          : (inpuType = 'password')
                      "
                      ><i class="bx bx-hide" v-if="inpuType == 'password'"></i>
                      <i class="bx bx-show" v-else></i
                    ></span>
                  </div>
                  <div class="form-text text-danger px-1" v-show="erro">
                    Senha incorreta
                  </div>
                </div>
                <div class="mb-3">
                  <button class="btn btn-primary d-grid w-100" @click="logar">
                    Acessar
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-left m-b-0">
                    Versão {{ version }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 px-0 h-100 d-none d-md-flex">
        <img src="img/fundo.png" alt="" class="w-100 h-100" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: "",
      senha: "",
      lembrar: false,
      inpuType: "password",
      erro: false,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    logar() {
      this.erro = false;
      this.login(
        {
          login: this.user,
          password: this.senha,
        },
        (data) => {
          window.location = "/";
        },
        () => {
          this.erro = true;
        }
      );
    },
  },
};
</script>

<style>
.login-card {
  background-color: #ccc;
}
</style>