<template>
  <div>
    <ul class="p-0 m-0">
      <li class="pb-1 d-flex" v-for="(d, i) in info" :key="i">
        <div class="mr-1">
          <span class="badge bg-label-primary p-2"
            ><i class="bx bx-time text-primary"></i
            >{{ (d.cpu / 1000).toFixed(2) }}</span
          >
        </div>

        <!-- <div class="avatar flex-shrink-0 me-3">
                        {{d.cpu}}
                    </div> -->
        <div class="w-100 flex-wrap align-items-center">
          <div class="me-2">
            <small class="text-muted d-block mb-1 ml-1">
              PID {{ d.pid }}
              <span class="text-info" v-if="d.time != null"
                >USO {{ d.time }}</span
              ></small
            >
            <small class="mb-0">{{
              d.commandLine ? d.commandLine : d.command
            }}</small>
          </div>
          <div v-if="d.children" :style="'margin-left: ' + m * 10 + 'px;'">
            <stick :info="d.children" :m="m + 1"></stick>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "stick",
  props: ["info", "m"],
};
</script>