<template>
  <div class="h-100">
    <layout ref="layout">
      <template v-slot:body>
        <div>
          <div class="row">
            <div class="col-12 order-3 order-md-2">
              <div class="card p-3">
                <h2>Rotas de API com token por usuário</h2>
                <h5 class="card-header m-0 me-2 pb-3">Lendo o QR Code</h5>
                <div class="block-code">
                  <div class="code">
                    &ltimg src="/api/auth/qr-imagem?token={token}&gt&lt/img&gt
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200: <span class="example-code">// QR Code gerado</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">Enviar mensagem</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">POST</span>
                    <span>/api/wpp/mensagem</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">//Token de acesso</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        Content-Type: application/json
                        <span class="required-code">*</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        value: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code"
                          >// Olá tudo bem ou base 64 para imagens</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        number: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// 55519990000</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        type: <span class="type-code">String</span>
                        <span class="example-code"
                          >// text/plain para texto ou image/png para
                          imagem</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        cation: <span class="type-code">String</span>
                        <span class="example-code"
                          >// Texto abaixo de imagem</span
                        >
                      </span>
                    </div>
                  </div>

                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Mensagem enviada com sucesso</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        400:
                        <span class="example-code">// Erro na requisicao</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        406:
                        <span class="example-code"
                          >// Não foi possível enviar</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">Consultar status</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/auth/status</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">//Token de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        <span class="type-code">{"status" : "online"}</span>
                        <span class="example-code">// Online</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        <span class="type-code"
                          >{"status" : "online", "expire": 1000}</span
                        >
                        <span class="example-code"
                          >// Apenas quando estiver gerando um QR code, é o
                          tempo de expiração em ms</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        <span class="type-code">{"status" : "offline"}</span>
                        <span class="example-code">// Offline</span>
                      </span>
                    </div>
                    <br />
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Mensagem enviada com sucesso</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>

                <h5 class="card-header m-0 me-2 pb-3">Logout</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">DELETE</span>
                    <span>/api/auth/logout</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">//Token de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        204:
                        <span class="example-code">// Logout com sucesso</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        400:
                        <span class="example-code"
                          >// Whatsapp não esta online</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>

                <h5 class="card-header m-0 me-2 pb-3">Chats</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/wpp/chats</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">//Token de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Retorna json dos chats</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        400:
                        <span class="example-code">// Falha ao buscar</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        404:
                        <span class="example-code"
                          >// Whatsapp não esta online</span
                        >
                      </span>
                    </div>
                  </div>
                </div>

                <h5 class="card-header m-0 me-2 pb-3">Contatos</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/wpp/contatos</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">//Token de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Retorna json dos contatos</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        400:
                        <span class="example-code">// Falha ao buscar</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        404:
                        <span class="example-code"
                          >// Whatsapp não esta online</span
                        >
                      </span>
                    </div>
                  </div>
                </div>

                <h5 class="card-header m-0 me-2 pb-3">
                  Imagem de qualquer perfil
                </h5>
                <div class="block-code">
                  <div class="code">
                    &ltimg
                    src="/api/wpp/picture/{numero}?token={token}&gt&lt/img&gt
                  </div>
                  <span class="my-2"
                    >A imagem do perfil é de acordo com que o usuário tem
                    acesso, colocar o número do proprio usuário retorna a imagem
                    do perfil dele</span
                  >
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        numero: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// 5599900000</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200: <span class="example-code">// Foto do perfil</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>

                <h5 class="card-header m-0 me-2 pb-3">
                  Informações do usuário
                </h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/wpp/info</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>

                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Retorna json dos dados</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        400:
                        <span class="example-code">// Falha ao buscar</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        404:
                        <span class="example-code"
                          >// Whatsapp não esta online</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 order-3 order-md-2 mt-5">
              <div class="card p-3">
                <h2>Rotas de sistema com a senha</h2>
                <h5 class="card-header m-0 me-2 pb-3">
                  Status do sistem processador, memoria
                </h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/system/info</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Json com os dados do sistema</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">
                  Tokens salvos no sistema
                </h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/system/tokens</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code">// Json com os dados</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">Apagar token</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">DELETE</span>
                    <span>/api/system/token/{token}</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        token: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code"
                          >// Token para ser deletado</span
                        >
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        204:
                        <span class="example-code"
                          >// Deletado com sucesso</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">Logs do token</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/system/log/{token}</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        token: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Token do sistema</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Json com os dados do sistema</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">Iniciar cliente</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">POST</span>
                    <span>/api/system/iniciar/{token}</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        token: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Token do sistema</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        204:
                        <span class="example-code"
                          >// Iniciado com sucesso</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        404: <span class="example-code">// Não encontrado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">
                  Clientes onlines e parados
                </h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/system/clientes</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        token: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Token do sistema</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Json com os dados dos clientes</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">Parar cliente</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">DELETE</span>
                    <span>/api/system/parar/{token}</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        token: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Token do sistema</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Json com os dados do sistema</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">Webhooks do sistema</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/system/webhook</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Json com os dados do sistema</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">Atualizar os webhooks</h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">POST</span>
                    <span>/api/system/webhook</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        Json: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code"
                          >// json com a configuração</span
                        >
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        204:
                        <span class="example-code">// Salvo com sucesso</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5 class="card-header m-0 me-2 pb-3">
                  Pegar os números de um cliente
                </h5>
                <div class="block-code">
                  <div class="code">
                    <span class="method">GET</span>
                    <span>/api/system/numeros/{token}</span>
                  </div>
                  <h4 class="header-title">Headers</h4>
                  <div class="code">
                    <div>
                      <span>
                        Authorization: Bearer
                        <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Senha de acesso</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Payload</h4>
                  <div class="code">
                    <div>
                      <span>
                        token: <span class="type-code">String</span>
                        <span class="required-code">*</span>
                        <span class="example-code">// Token do sistema</span>
                      </span>
                    </div>
                  </div>
                  <h4 class="payload-title">Resposta</h4>
                  <div class="code">
                    <div>
                      <span>
                        200:
                        <span class="example-code"
                          >// Json com os numeros do token</span
                        >
                      </span>
                    </div>
                    <div>
                      <span>
                        401: <span class="example-code">// Não autorizado</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "../components/layout/Tema.vue";
export default {
  components: {
    layout,
  },
};
</script>
<style>
.code {
  background-color: #2d2d2d;
  color: #fff;
  font-family: Courier, monospace;
  padding: 10px;
  border-radius: 0.2rem;
}

.code .method {
  background-color: #2b86ee;
  padding: 5px;
  border-radius: 3px;
}

.code div {
  margin-top: 5px;
  margin-bottom: 5px;
}

.block-code {
  padding: 10px;
}

.type-code {
  color: #59f0d7;
}

.required-code {
  color: #ee8e34;
}

.example-code {
  color: #a5a5a5;
}

.header-title,
.payload-title {
  margin-top: 15px;
  margin-bottom: 10px;
  /* background-color: #7481f1; */
  color: #2166e7;
  padding: 5px;
  border-bottom: 1px solid #2166e7;
  font-size: 18px;
}

br {
  margin-bottom: 5px;
}
</style>