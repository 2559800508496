<template>
  <div class="h-100">
    <layout ref="layout">
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <div class="card px-3 pb-4">
              <h5 class="card-header">Threads</h5>
              <div class="table table-striped table-bordered">
                <table class="table">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white">#</th>
                      <th style="max-width: 500px" class="text-white">Nome</th>
                      <th class="text-white">ms</th>
                      <th class="text-white">%</th>
                      <th class="text-white">Status</th>
                    </tr>
                  </thead>

                  <tbody class="table-border-bottom-0">
                    <tr v-for="(t, i) in info" :key="i">
                      <td>{{ i }}</td>
                      <td>{{ t.name }}</td>
                      <td>{{ t.time_ms }}</td>
                      <td>{{ t.per.toFixed(2) }}%</td>
                      <td>{{ t.state }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "../components/layout/Tema.vue";
export default {
  components: {
    layout,
  },
  data() {
    return {
      run: null,
      info: null,
    };
  },

  mounted() {
    this.run = this.getRun(
      "/system/info",
      (res) => {
        this.info = res.data;
      },
      1000
    );
  },
  beforeUnmount() {
    clearInterval(this.run);
  },
};
</script>
<style>
</style>